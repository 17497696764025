import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import { ProjectContentCard } from '../ProjectContentCard';
import Section from '../Section';
import markdownRenderer from '../MarkdownRenderer';
import SeeProjects from '../../sections/SeeProjects';
import {
  ProjectImageLeft,
  ProjectImageRight,
  ProjectImageTransparent,
} from '../ProjectImage';
import ProjectTitle from '../ProjectTitle';

const TimeMachineContent = () => (
  <Section.Container id="Time Machine Content">
    <StaticQuery
      query={graphql`
        query TimeMachineContent {
          contentfulTimeMachine {
            introduction {
              introduction
            }
            client {
              client
            }
            challenge {
              challenge
            }
            solution {
              solution
            }
            responsibility {
              responsibility
            }
            contentPictures {
              id
              title
              file {
                url
                fileName
              }
            }
            contentPictures2 {
              id
              title
              file {
                url
                fileName
              }
            }
            transparentPictures {
              id
              title
              file {
                url
                fileName
              }
            }
            videoSection
            additionalTitle
            additionalInfo {
              additionalInfo
            }
          }
        }
      `}
      render={({ contentfulTimeMachine }) => {
        const imgTimeMachine1 = contentfulTimeMachine.contentPictures.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 1',
        );
        const imgTimeMachine2 = contentfulTimeMachine.contentPictures.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 2',
        );
        const imgTimeMachine3 = contentfulTimeMachine.contentPictures.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 3',
        );
        const imgTimeMachine4 = contentfulTimeMachine.contentPictures.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 4',
        );
        const imgTimeMachine5 = contentfulTimeMachine.contentPictures.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 5',
        );
        const imgTimeMachine6 = contentfulTimeMachine.contentPictures2.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 6',
        );
        const imgTimeMachine7 = contentfulTimeMachine.contentPictures2.filter(
          object => object.title === 'Puppenkiste Zeitmaschine 7',
        );
        const imgGears = contentfulTimeMachine.transparentPictures.filter(
          object => object.title === 'Gears',
        );
        const imgButtons = contentfulTimeMachine.transparentPictures.filter(
          object => object.title === 'Buttons',
        );

        return (
          <Fragment>
            <ProjectContentCard>
              <Flex justifyContent="top" flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Introduction</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulTimeMachine.introduction.introduction}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgTimeMachine1.map(img => (
                    <ProjectImageRight src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgButtons.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Client</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulTimeMachine.client.client}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Challenge</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulTimeMachine.challenge.challenge}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>

                  <Box width={[1, 1, 1]}>
                    {imgGears.map(img => (
                      <ProjectImageTransparent
                        src={img.file.url}
                        key={img.id}
                      />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTimeMachine2.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTimeMachine3.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTimeMachine4.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTimeMachine5.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 2 / 3]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Solution</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulTimeMachine.solution.solution}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Responsibility</ProjectTitle>
                    <ReactMarkdown
                      source={
                        contentfulTimeMachine.responsibility.responsibility
                      }
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTimeMachine6.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTimeMachine7.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>
              </Flex>
            </ProjectContentCard>

            {contentfulTimeMachine.videoSection.map(video => (
              <Fade bottom>
                <ProjectContentCard
                  style={{ background: '#000000' }}
                  key={video}
                >
                  <Box width={[1, 1, 1]} px={[0, 30, 40]}>
                    <iframe
                      title="Puppenkistenvideo"
                      width="100%"
                      height="400"
                      src={video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </Box>
                </ProjectContentCard>
              </Fade>
            ))}

            <Fade bottom>
              <ProjectContentCard>
                <Box width={[1, 1, 1]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>
                      {contentfulTimeMachine.additionalTitle}
                    </ProjectTitle>
                    <ReactMarkdown
                      source={
                        contentfulTimeMachine.additionalInfo.additionalInfo
                      }
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>
              </ProjectContentCard>
            </Fade>
            <SeeProjects />
          </Fragment>
        );
      }}
    />
  </Section.Container>
);

export default TimeMachineContent;
