import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Box } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import MouseIcon from '../MouseIcon';
import { Hero, Subheading } from '../ProjectHeading';

const Banner = styled(Image)`
  margin-bottom: 0px;
`;

const HeaderBox = styled(Box)`
  position: absolute;
  font-style: italic;

  @media only screen and (max-width: 700px) {
  }
`;

const HeaderContainer = styled.div`
  transform: rotate(-10deg);
`;

const TimeMachineIntro = () => (
  <StaticQuery
    query={graphql`
      query TimeMachineIntro {
        contentfulTimeMachine {
          title
          subtitle
          banner {
            file {
              url
            }
          }
        }
      }
    `}
    render={({ contentfulTimeMachine }) => {
      return (
        <Fragment>
          <HeaderBox pt={[5, 80, 40]} px={[4, 4, 4]}>
            <HeaderContainer>
              <Hero
                as="h1"
                color="totalDark"
                fontSize={[5, 6, 8]}
                mb={[2, 3, 4]}
              >
                {contentfulTimeMachine.title}
              </Hero>
            </HeaderContainer>

            <HeaderContainer>
              <Subheading
                as="h2"
                color="totalDark"
                fontSize={[4, 5, 6]}
                mt={[3, 2, 150, 380]}
                mb={[3, 5]}
              >
                {contentfulTimeMachine.subtitle}
              </Subheading>
            </HeaderContainer>
          </HeaderBox>

          <div>
            <Banner
              mt={[170, 50, -20]}
              src={contentfulTimeMachine.banner.file.url}
              width="97%"
              alt="Puppenkiste Zeitmaschine"
            />
          </div>

          <SectionLink section="Time Machine Content">
            {({ onClick }) => <MouseIcon onClick={onClick} />}
          </SectionLink>
        </Fragment>
      );
    }}
  />
);

export default TimeMachineIntro;
