import React from 'react';
import Layout from '../../components/Layout';
import ProjectHeader from '../../components/ProjectHeader';
import TimeMachineIntro from '../../components/TimeMachine/TimeMachineIntro'
import TimeMachineContent from '../../components/TimeMachine/TimeMachineContent'
import Footer from '../../components/Footer';

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <TimeMachineIntro />
    <TimeMachineContent />
    <Footer />
  </Layout>
);

export default IndexPage;
